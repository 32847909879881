import { TRACKCB_CONVERSION_API_SYNC_TYPES } from '../../config/constants/meta/mediaPlatformApiSettingsConstants';
import { formatBrandName } from '../brandUtils';

export const buildTrackCBSections = (brandFetchStatusData, brands) => [
  {
    title: 'Last Sync',
    data: getSyncStatusData({
      brandFetchStatusData,
      brands,
      type: TRACKCB_CONVERSION_API_SYNC_TYPES.LAST_SYNC,
    }),
  },
  {
    title: '"Last 7 Days" Sync',
    data: getSyncStatusData({
      brandFetchStatusData,
      brands,
      type: TRACKCB_CONVERSION_API_SYNC_TYPES.SEVEN_DAYS,
    }),
  },
];

const getSyncStatusData = ({ brandFetchStatusData, brands, type }) => {
  const filteredData =
    type === TRACKCB_CONVERSION_API_SYNC_TYPES.SEVEN_DAYS
      ? brandFetchStatusData.filter(item => item.seven_days_data_update)
      : brandFetchStatusData;

  return filteredData.map(item => formatSyncEntry({ item, brands, type }));
};

const formatSyncEntry = ({ item, brands, type }) => {
  const brandNameWithId = formatBrandName(item.brand_id, brands);

  switch (type) {
    case TRACKCB_CONVERSION_API_SYNC_TYPES.LAST_SYNC: {
      const time = item.last_update_time ? `Last Update: ${item.last_update_time}` : 'No updates yet';
      const status = item.error ? 'Error' : 'Success';
      return `${brandNameWithId} (${time}, Status: ${status})`;
    }

    case TRACKCB_CONVERSION_API_SYNC_TYPES.SEVEN_DAYS: {
      const { period, zero_events_count } = item.seven_days_data_update;
      const status = zero_events_count ? 'No events found' : 'Events found and processed';
      return `${brandNameWithId} (Period: ${period}, Status: ${status})`;
    }

    default:
      return '';
  }
};
